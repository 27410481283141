export const colors = {
    LandingPage_Background: '#FFFFFF',
    LandingPage_Description: '#373737',
    Button_Primary: '#02EBCC',
    Button_Secondary: '#02EBCC', 
    Header_Background: "#FFFFFF",
    Header_Content: '#000000',
    Black: '#000000',
    Header: '#383C3D',
    Header_Text: "#FFFFFF",
    Header_Text_back: "#4C5152",
    Table_Header: '#BFCACD',
    Action_Button_Back: '#00C6AF',
    ALERT_BUTTON_GOTO_COLOR: "#c6e2f6",
    ALERT_BUTTON_CLOSE_COLOR:  "#00C6AF",
    HEADER: '#2a383b',
    BORDER_BACKGROUND: "#86FFCC",
    CAR_BOX_EVEN:'#FFFFFF',
    CAR_BOX_ODD:'#FFFFFF'
}