import {
    FETCH_ZONES,
    FETCH_ZONES_SUCCESS,
    FETCH_ZONES_FAILED,
    EDIT_ZONE
  } from "../store/types";
  import store from '../store/store';
  import { firebase } from '../config/configureFirebase';
  
  export const fetchZoneData = () => (dispatch) => {
    const {
        zonesRef
    } = firebase;
    dispatch({
      type: FETCH_ZONES,
      payload: null
    });
    zonesRef.on("value", snapshot => {
      if (snapshot.val()) {
          const data = snapshot.val();
          const arr = Object.keys(data).map(i => {
              data[i].id = i;
              return data[i]
          });
          dispatch({
              type: FETCH_ZONES_SUCCESS,
              payload: arr
          });
      } else {
          dispatch({
              type: FETCH_ZONES_FAILED,
              payload: store.getState().languagedata.defaultLanguage.no_zones
          });
      }
    });
  }
  
  export const editZone= (zone, method) => async (dispatch) => {
    const {
      zonesRef, 
      zoneEditRef,
    } = firebase;
    dispatch({
      type: EDIT_ZONE,
      payload: { method, zone }
    });
    if (method === 'Add') {
      zonesRef.push(zone);
    } else if (method === 'Delete') {
      zoneEditRef(zone.id).remove();
    }else {
      zoneEditRef(zone.id).update(zone);
    }
  }